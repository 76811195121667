<template>
    <div class="container-fluid p-0" >
            <div class="map-container" style=" left: 0px;">
               <div class="container">
                  <div class="row about-us-row text-center align-items-center">
                     <div class="col-md-6">
                        <div class="text-center">
                           <img width="497" height="477" src="@/assets/images/frontend/about-us/06.jpg" class="attachment-large size-large" alt="" loading="lazy" >
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class=" text-left iq-title-box iq-title-default">
                           <h2 class="iq-title">Contact Us Here</h2>
                           <p class="iq-title-desc">Streamit is located in Los Angeles city and you can contact us at <a href="#">hello@streamit.com</a> for any tech-related support and assistance. We love to hear from our Streamit users.</p>
                           <div class="row mt-2">
                              <div class="col-md-4">
                                 <div class="counter">
                                    <span class="counter-number" data-duration="2000" data-to-value="4" data-from-value="0" data-delimiter=",">4</span>
                                 </div>
                                 <div class="counter-title">Branch</div>
                              </div>
                              <div class="col-md-4">
                                 <div class="iq-contact-list" data-element_type="column">
                                 <div class="counter">
                                    <span class="counter-number" data-duration="2000" data-to-value="4" data-from-value="0" data-delimiter=",">
                                       500+
                                    </span>
                                 </div>
                                 <div class="counter-title">Employee</div>
                                 </div>
                              </div>
                              <div class="col-md-4">
                                 <div class="iq-contact-list" data-element_type="column">
                                 <div class="counter">
                                    <span class="counter-number" data-duration="2000" data-to-value="4" data-from-value="0" data-delimiter=",">
                                       1,000+
                                    </span>
                                 </div>
                                 <div class="counter-title">Clients</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
</template>
<script>
export default {
  name: 'ContactUs'
}
</script>
